import React, { useRef, useState } from 'react'
import { Form, Button, Card, Alert } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { functions } from '../firebase';
import { httpsCallable } from "firebase/functions";
import { getDatabase, ref, set } from "firebase/database";

export default function Signup() {
    //Refs
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const userTypeRef = useRef();
    const pharmacyNameRef = useRef();
    const officeNameRef = useRef();
    const { signup, setRole, setLocation } = useAuth();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const db = getDatabase();
    const [showPharmacyName, setShowPharmacyName] = useState(false);
    const [showOfficeName, setShowOfficeName] = useState(false);



    async function handleSubmit(e) {
        e.preventDefault()

        //Check if the two passwords match
        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError("Passwords do not match")
        }

        try {
            setError("")
            setLoading(true)
            await signup(emailRef.current.value, passwordRef.current.value).then(async (userCred) => {
                //! This is where you would add additional user data to the database
                //get the user
                const user = userCred.user;
                //Call the serverside function depending on the selected user role
                const addDefaultClaims = httpsCallable(functions, 'addDefaultClaims');
                switch (userTypeRef.current.value) {
                    case "pharmacy":
                        //Add custom claims
                        await addDefaultClaims({ email: emailRef.current.value,
                                                role: "pharmacy",
                                                location: pharmacyNameRef.current.value }).then((msg) => {

                            console.log(msg.data.message);
                        });

                        //set the user location to the pharmacy
                        setLocation(pharmacyNameRef.current.value);
                        //Set the userCredential to the pharmacy
                        setRole("pharmacy");
                        //Navigate to the pharmacy page
                        navigate("/pharmacy");
                        //Write the user to the database
                        await set(ref(db, 'pharmacies/' + pharmacyNameRef.current.value + '/users/' + user.uid), {
                            //Set the user data
                            email: user.email,
                            created: user.metadata.creationTime,
                            role: "pharmacy",
                        });
                        break;
                    case "provider":
                        await addDefaultClaims({ email: emailRef.current.value,
                                                role: "provider",
                                                location: officeNameRef.current.value }).then((msg) => {

                            console.log(msg.data.message);
                        });
                        //Set the user location to the office
                        setLocation(officeNameRef.current.value);
                        //Set the userCredential to the provider
                        setRole("provider");
                        //Navigate to the provider page
                        navigate("/provider");
                        //Write the user to the database
                        await set(ref(db, 'offices/' + officeNameRef.current.value + "/users/" + user.uid), {
                            //Set the user data
                            email: user.email,
                            created: user.metadata.creationTime,
                            role: "provider",
                        });
                        break;
                    case "admin-provider":
                        await addDefaultClaims({ email: emailRef.current.value,
                            role: "admin-provider",
                            location: officeNameRef.current.value }).then((msg) => {

                            console.log(msg.data.message);
                        });
                        //Set the user location to the office
                        setLocation(officeNameRef.current.value);
                        //Set the userCredential to the admin-provider
                        setRole("providerAdmin");
                        //Navigate to the admin-provider page
                        navigate("/admin-provider");
                        //Write the user to the database
                        await set(ref(db, 'offices/' + officeNameRef.current.value + "/users/" + user.uid), {
                            //Set the user data
                            email: user.email,
                            created: user.metadata.creationTime,
                            role: "adminProvider",
                        });
                        break;
                    case "admin":
                        await addDefaultClaims({ email: emailRef.current.value,
                            role: "admin",
                            location: "null" }).then((msg) => {

                            console.log(msg.data.message);
                        });
                        //Set the userCredential to the admin
                        setRole("admin");
                        //Navigate to the admin page
                        navigate("/admin");
                        //Write the user to the database
                        await set(ref(db, 'Admins/' + user.uid), {
                            //Set the user data
                            email: user.email,
                            created: user.metadata.creationTime,
                            role: "admin",
                        });
                        break;
                    default:
                        setError("Invalid user type");
                }
                //force refresh the usertoken
                await userCred.user.getIdToken(true);
            }).catch((err) => {
                setError(err.message);
            });
        } catch {
            setError("Failed to create an account")
        }

        setLoading(false)
    }

    //Check if the user type is pharmacy
    const checkToShow = () => {
        if (userTypeRef.current && userTypeRef.current.value === "pharmacy") {
            setShowPharmacyName(true);
            setShowOfficeName(false);
        } else if (userTypeRef.current && (userTypeRef.current.value === "provider" || userTypeRef.current.value === "admin-provider")) {
            setShowPharmacyName(false);
            setShowOfficeName(true);
        } else {
            setShowPharmacyName(false);
            setShowOfficeName(false);
        }
    }

    return (
    <>
        <Card>
            <Card.Body>
                <h2 className="text-center mb-4">Sign Up</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group id="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" ref={emailRef} required />
                    </Form.Group>
                    <Form.Group id="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" ref={passwordRef} required />
                    </Form.Group>
                    <Form.Group id="password-confirm">
                        <Form.Label>Password Confirmation</Form.Label>
                        <Form.Control type="password" ref={passwordConfirmRef} required />
                    </Form.Group>
                    <Form.Group id="user-type">
                        <Form.Label>User Type</Form.Label>
                        <Form.Control as="select" ref={userTypeRef} onClick={checkToShow} required>
                            <option value="pharmacy">Pharmacy</option>
                            <option value="provider">Provider</option>
                            <option value="admin-provider">Admin Provider</option>
                            <option value="admin">Admin</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group id="pharmacyName" hidden={!showPharmacyName}>
                        <Form.Label>Pharmacy Location</Form.Label>
                        <Form.Control type="text" ref={pharmacyNameRef} />
                    </Form.Group>
                    <Form.Group id="officeName" hidden={!showOfficeName}>
                        <Form.Label>Office Name</Form.Label>
                        <Form.Control type="text" ref={officeNameRef} />
                    </Form.Group>
                    <Button disabled={loading} className="w-100 mt-3" type="submit">
                        Sign Up
                    </Button>
                </Form>
            </Card.Body>
        </Card>
        <div className="w-100 text-center mt-2">
            Already have an account? <Link to="/login">Log In</Link>
        </div>
    </>

  )
}
