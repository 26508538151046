import React, { useState, useRef } from 'react'
import { Alert, Button, Card, Container, Form } from 'react-bootstrap'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { getDatabase, ref, set, get } from "firebase/database";

export default function Provider() {
  //consts
  const [error, setError] = useState("")
  const { logout, officeLocation } = useAuth()
  const [loading, setLoading] = useState(false);

  const db = getDatabase();
  //Refs
  const patientNameRef = useRef()
  const patientEmailRef = useRef()
  const drugNameRef = useRef()
  const drugQuantityRef = useRef()
  const drugDosageRef = useRef()
  const drugDurationRef = useRef()
  const drugFrequencyRef = useRef()
  const drugRefillsRef = useRef()
  const pharmacyRef = useRef()

  //general details
  document.title = "Provider Dashboard"

  //logout
  async function handleLogout() {
    setError("")
    try {
        await logout()
        Navigate("/login")
    } catch {
        setError("Failed to log out")
    }
  }

  //prescribe
  async function handlePrescribe(e) {
    e.preventDefault()


    try {
      setError("")
      setLoading(true)

      //make an object of all the values
      const prescritionObject = {
        patientName: patientNameRef.current.value,
        patientEmail: patientEmailRef.current.value,
        pharmacyName: pharmacyRef.current.value,
        drugName: drugNameRef.current.value,
        drugQuantity: drugQuantityRef.current.value,
        drugDosage: drugDosageRef.current.value,
        drugDuration: drugDurationRef.current.value,
        drugFrequency: drugFrequencyRef.current.value,
        drugRefills: drugRefillsRef.current.value
      }

      //! write the prescription to the database
      //*Check first to see if the pharmacy exists in the db
      const pharmacyExists = await get(ref(db, 'pharmacies/' + pharmacyRef.current.value));
      //get the users office
      
      if (pharmacyExists.exists()) {
        //write the prescription to the offices prescription list
        await set(ref(db, 'offices/' + officeLocation + '/prescriptions/' + prescritionObject.patientName), {
          patientName: prescritionObject.patientName,
          patientEmail: prescritionObject.patientEmail,
          pharmacyName: prescritionObject.pharmacyName,
          drugName: prescritionObject.drugName,
          drugQuantity: prescritionObject.drugQuantity,
          drugDosage: prescritionObject.drugDosage,
          drugDuration: prescritionObject.drugDuration,
          drugFrequency: prescritionObject.drugFrequency,
          drugRefills: prescritionObject.drugRefills
        });

        //write the prescription to the pharmacys prescription list
        await set(ref(db, 'pharmacies/' + prescritionObject.pharmacyName + '/prescriptions/' + prescritionObject.patientName), {
          patientName: prescritionObject.patientName,
          patientEmail: prescritionObject.patientEmail,
          pharmacyName: prescritionObject.pharmacyName,
          drugName: prescritionObject.drugName,
          drugQuantity: prescritionObject.drugQuantity,
          drugDosage: prescritionObject.drugDosage,
          drugDuration: prescritionObject.drugDuration,
          drugFrequency: prescritionObject.drugFrequency,
          drugRefills: prescritionObject.drugRefills
        });

        //clear the form
        patientNameRef.current.value = ""
        patientEmailRef.current.value = ""
        pharmacyRef.current.value = ""
        drugNameRef.current.value = ""
        drugQuantityRef.current.value = ""
        drugDosageRef.current.value = ""
        drugDurationRef.current.value = ""
        drugFrequencyRef.current.value = ""
        drugRefillsRef.current.value = ""

        setError("")
      } else {
        setError("Pharmacy does not exist")
      }
    } catch {
      setError("Failed to prescibe drug")
    }

    setLoading(false)
  }


  return (
    <>
      <Card className='m-2'>
        <Card.Body>
          <h2 className='text-center mb-4'>Provider</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <strong>Office Location: </strong> {officeLocation}
          <hr />

          {/* list of prescibed drugs here */}
          <Container>
            <h2 className='text-center mb-3'>Prescriptions</h2>
              <div id="prescriptionList" className='text-center mb2'>
                  {/* Filled by JS */}
              </div>
          </Container>

          <hr />

          {/* Prescription form here */}
          <Container>
            <h2 className='text-center mb-3'>Prescribe</h2>
            <Form onSubmit={handlePrescribe}>
              <Form.Group className='text-center mb-2'id="patientName">
                <Form.Label>Patient Name</Form.Label>
                <Form.Control type="text" ref={patientNameRef} required />
              </Form.Group>
              <Form.Group className='text-center mb-2'id="patientEmail">
                <Form.Label>Patient Email</Form.Label>
                <Form.Control type="email" ref={patientEmailRef} required />
              </Form.Group>
              <Form.Group className='text-center mb-2'id="pharmacyName">
                <Form.Label>Pharmacy</Form.Label>
                <Form.Control type='text' ref={pharmacyRef} required/>
              </Form.Group>
              <Form.Group className='text-center mb-2'id="drugName">
                <Form.Label>Drug Name</Form.Label>
                <Form.Control type="text" ref={drugNameRef} required />
              </Form.Group>
              <Form.Group className='text-center mb-2'id="drugQuantity">
                <Form.Label>Drug Quantity</Form.Label>
                <Form.Control type="number" ref={drugQuantityRef} required />
              </Form.Group>
              <Form.Group className='text-center mb-2'id="drugDosage">
                <Form.Label>Drug Dosage</Form.Label>
                <Form.Control type="text" ref={drugDosageRef} required />
              </Form.Group>
              <Form.Group className='text-center mb-2'id="drugFrequency">
                <Form.Label>Drug Frequency</Form.Label>
                <Form.Control type="text" ref={drugFrequencyRef} required />
              </Form.Group>
              <Form.Group className='text-center mb-2'id="drugDuration">
                <Form.Label>Drug Duration</Form.Label>
                <Form.Control type="text" ref={drugDurationRef} required />
              </Form.Group>
              <Form.Group className='text-center mb-2'id="drugRefills">
                <Form.Label>Drug Refills</Form.Label>
                <Form.Control type="number" ref={drugRefillsRef} required />
              </Form.Group>
              <Button disabled={loading} className="w-100 mt-3" type="submit">
                Prescribe
              </Button>
            </Form>
          </Container>

          <hr />

          <div className="w-100 text-center mt-2">
            <Button className="btn-danger" onClick={handleLogout}>Logout</Button>
          </div>
        </Card.Body>
      </Card>
    </>
  )
}
