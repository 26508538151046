import React, { useRef, useState } from 'react'
import { Form, Button, Card, Alert } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'

export default function Login() {
    //Refs
    const emailRef = useRef();
    const passwordRef = useRef();
    const { login, setRole, setLocation } = useAuth();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();


    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setError("")
            setLoading(true)
            await login(emailRef.current.value, passwordRef.current.value).then(async (userCred) => {
                //current user role for the frame
                var frameRole = "";
                //update the auth user role
                await userCred.user.getIdTokenResult(true).then(async (idTokenResult) => {
                    //Add custom claims to the user data

                    //set the role
                    frameRole = idTokenResult.claims.role;
                    await setRole(idTokenResult.claims.role);
                    await setLocation(idTokenResult.claims.location);
                }).catch((err) => {
                    setError("Failed to get Id token result");
                });


                //Navigate to the correct page depending on the user role
                console.log("Current Role: " + frameRole);
                switch (frameRole) {
                    case "pharmacy":
                        navigate("/pharmacy");
                        break;
                    case "provider":
                        navigate("/provider");
                        break;
                    case "providerAdmin":
                        navigate("/providerAdmin");
                        break;
                    case "admin":
                        navigate("/admin");
                        break;
                    default:
                        setError("Current Role Is not being read correctly");
                        break;
                }

            }).catch((err) => {
                setError(err.message);
            });

        } catch {
            setError("Failed to sign in!")
        }


        setLoading(false)
    }

    return (
    <>
        <Card>
            <Card.Body>
                <h2 className="text-center mb-4">Log In</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group id="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" ref={emailRef} required />
                    </Form.Group>
                    <Form.Group id="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" ref={passwordRef} required />
                    </Form.Group>
                    <Button disabled={loading} className="w-100 mt-3" type="submit">
                        Log In
                    </Button>
                </Form>
            </Card.Body>
        </Card>
        <div className="w-100 text-center mt-2">
            Need an account? <Link to="/signup">Sign Up</Link>
        </div>
    </>

  )
}
