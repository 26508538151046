import { confirmPasswordReset } from 'firebase/auth';
import React, { useState, useRef } from 'react'
import { Card, Form, Button, Alert } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../../firebase';



export default function PasswordReset() {
    document.title = "Password Reset";

    //refs
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const navigate = useNavigate();

    //use states
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)

    //querrys
    const query = useQuery();


    //get query string
    function useQuery() {
        const location = useLocation();
        return new URLSearchParams(location.search);
    }

    //handle submit
    async function handleSubmit(e) {
        e.preventDefault();
        try {
            setError("")
            setLoading(true)

            //check if passwords match
            if (passwordRef.current.value !== passwordConfirmRef.current.value) {
                return setError("Passwords do not match")
            }

            //update password
            confirmPasswordReset(auth, query.get('oobCode'), passwordRef.current.value).then(() => {
                console.log("Password reset successful");
                navigate("/login");
            }).catch(err => {
                console.log(err);
            });

        } catch {
            setError("Failed to reset password")
        }
        setLoading(false)
    }


    return (
        <>
            <Card>
                <Card.Body>
                    <h2 className="text-center mb-4">Update Password</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className='text-center mb-2' id="password">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control type="password" ref={passwordRef} required />
                        </Form.Group>
                        <Form.Group className='text-center mb-2' id="password-confirm">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control type="password" ref={passwordConfirmRef} required />
                        </Form.Group>
                        <Button disabled={loading} className="w-100 mt-3" type="submit">
                            Update
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </>
    )
}
