import React, { useRef, useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Form, Container, Button, Alert } from 'react-bootstrap';
import { httpsCallable } from "firebase/functions";
import { auth, functions } from '../../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useAuth } from '../../contexts/AuthContext';
import { Navigate } from 'react-router-dom';

export default function AdminProvider() {
  //refs
  const newUserEmail = useRef();
  const newUserName = useRef();
  const newUserRole = useRef();
  const newUserPharmacy = useRef();
  const newUserOffice = useRef();

  //use states
  const [newRole, setNewUserRole] = useState("null");
  const [loading, setLoading] = useState(false);
  const [showPharmacyName, setShowPharmacyName] = useState(false);
  const [showOfficeName, setShowOfficeName] = useState(false);
  const [error, setError] = useState("");
  const { logout } = useAuth()

  document.title = "Admin Dashboard";

  //handle submit
  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    setError("");

    //* This is where the user account needs to be created placed in the correct database and the user needs to be sent
    //* an email with a link to set their password
    //create the new user with a cloud function
    const createNewUser = httpsCallable(functions, 'createNewUser');
    await createNewUser({ email: newUserEmail.current.value }).then(result => {
      console.log(result.data.message);
      //send a password reset email
      sendPasswordResetEmail(auth, newUserEmail.current.value).then(() => {
        console.log("Password reset email sent");
      }).catch(err => {
        console.log(err);
      });
    }).catch(err => {
      console.log(err);
    });

    //Call the serverside function depending on the selected user role
    const addDefaultClaims = httpsCallable(functions, 'addDefaultClaims');
    switch (newUserRole.current.value) {
      case "pharmacy":
        //Add custom claims
        await addDefaultClaims({ email: newUserEmail.current.value, location: newUserPharmacy.current.value, role: newUserRole.current.value }).then(result => {
          console.log(result.data.message);
        }).catch(err => {
          console.log(err);
        });
        break;
      case "adminProvider":
        //Add custom claims
        await addDefaultClaims({ email: newUserEmail.current.value, location: newUserOffice.current.value, role: newUserRole.current.value }).then(result => {
          console.log(result.data.message);
        }).catch(err => {
          console.log(err);
        });
        break;
      case "admin":
        //Add custom claims
        await addDefaultClaims({ email: newUserEmail.current.value, location: "null", role: newUserRole.current.value }).then(result => {
          console.log(result.data.message);
        }).catch(err => {
          console.log(err);
        });
        break;
      default:
        setError("Case not found");
    }

    setLoading(false);
  }

  //handle logout
  async function handleLogout() {
    setError("");
    try {
      await logout();
      Navigate('/login');
    } catch {
      setError("Failed to log out");
    }
  }

  
  function updateSelectOption() {
    setNewUserRole(newUserRole.current.value);
  }

  //make a use effect to update the type of display to show based on the new user role
  useEffect(() => {
    //if the new user role is pharmacy, show the pharmacy name input
    if (newUserRole.current.value === 'pharmacy') {
      setShowPharmacyName(true);
    } else {
      setShowPharmacyName(false);
    }
    //if the new user role is admin provider, show the office name input
    if (newUserRole.current.value === 'adminProvider') {
      setShowOfficeName(true);
    } else {
      setShowOfficeName(false);
    }
  }, [newRole]);


  return (
    <>
      <Card>


        <Card.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        <div className="d-flex h-100">
          <div className="flex-grow-1 d-flex align-items-center p-3">
            <h1 className="text-center">Welcome to the Admin Dashboard</h1>
          </div>
        </div>
        </Card.Body>


      </Card>
      <Card>
        <Card.Body>


          <Container>
            <h2 className='text-center mb-3'>Create A User Account</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group className='text-center mb-2'id="userEmail">
                <Form.Label>New User Email</Form.Label>
                <Form.Control type="text" ref={newUserEmail} required />
              </Form.Group>
              <Form.Group className='text-center mb-2'id="userName">
                <Form.Label>New User Name</Form.Label>
                <Form.Control type="text" ref={newUserName} required />
              </Form.Group>
              <Form.Group className='text-center mb-2' id="user-type">
                <Form.Label>User Type</Form.Label>
                <Form.Control className='text-center mb-2' as="select" ref={newUserRole} onClick={updateSelectOption} required>
                  <option value="pharmacy">Pharmacy</option>
                  <option value="adminProvider">Admin Provider</option>
                  <option value="admin">Admin</option>
                </Form.Control>
              </Form.Group>
              <Form.Group className='text-center mb-2' id="pharmacyName" hidden={!showPharmacyName}>
                <Form.Label>Pharmacy Location</Form.Label>
                <Form.Control type="text" ref={newUserPharmacy} />
              </Form.Group>
              <Form.Group className='text-center mb-2' id="officeName" hidden={!showOfficeName}>
                  <Form.Label>Office Name</Form.Label>
                  <Form.Control type="text" ref={newUserOffice} />
              </Form.Group>
              <Button disabled={loading} className="w-100 mt-3" type="submit">
                Create
              </Button>
            </Form>
          </Container>

          <div className="w-100 text-center mt-2">
            <Button className="btn-danger" onClick={handleLogout}>Logout</Button>
          </div>

        </Card.Body>
      </Card>
    </>

  );
}