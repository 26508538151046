import React from 'react';
import { Container } from 'react-bootstrap';
import { AuthProvider } from '../contexts/AuthContext';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Signup from './Signup';
import Login from './Login';
import PrivateRoute from './PrivateRoute';
import PasswordReset from './ResetPages/PasswordReset';


function App() {
  document.title = "Nexum";
  return (
      <Container className='d-flex align-items-center justify-content-center'
          style={{ minHeight: "100vh"}}>
            <div className="w-100" style={{ maxWidth: "400px" }}>
              <Router>
                <AuthProvider>
                  <Routes>
                    <Route path="/admin" element={
                      <PrivateRoute />
                    } />
                    <Route path="/pharmacy" element={
                      <PrivateRoute />
                    } />
                    <Route path="/provider" element={
                      <PrivateRoute />
                    } />
                    <Route path="/providerAdmin" element={
                      <PrivateRoute />
                    } />
                    <Route path="/passwordReset" element={ <PasswordReset />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<Login />} />
                  </Routes>
                </AuthProvider>
              </Router>
            </div>
      </Container>
  );
}

export default App;
