import React, {useState, useEffect} from 'react'
import { Button, Card } from 'react-bootstrap'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { getDatabase, ref, onValue } from 'firebase/database'

export default function Pharmacy() {
  const [error, setError] = useState("")
  const { logout, currentRole, officeLocation } = useAuth()
  const db = getDatabase();

  useEffect(() => {
    updatePrescriptionHTML();
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  


  async function handleLogout() {
    setError("")
    try {
        await logout()
        Navigate("/login")
    } catch {
        setError("Failed to log out")
    }
  }


  var prescriptionListHTML = "";
  async function updatePrescriptionHTML() {
    //read the prescriptions assigned from the database
    var prescriptionRef = ref(db, "pharmacies/" + officeLocation + "/prescriptions");
    onValue(prescriptionRef, (snapshot) => {

      prescriptionListHTML = "<hr />";

      snapshot.forEach(function (childSnapshot) {
        var prescription = childSnapshot.val();
        //format the page
        prescriptionListHTML +=
          "<ul><li>Patient: <span>" + prescription.patientName + "</span></li>";
        prescriptionListHTML +=
          "<li>Prescription: <span>" + prescription.drugName + "</span></li>";
        prescriptionListHTML +=
          "<li>Pharmacy: <span>" + prescription.drugQauntity + "</span></li>";
        prescriptionListHTML +=
          "<li>Dose: <span>" + prescription.drugDosage + "</span></li>";
        prescriptionListHTML +=
          "<li>Refills: <span>" + prescription.drugRefills + "</span></li></ul><hr>";
      });

      prescriptionListHTML += "<hr />";
      document.getElementById("prescriptionList").innerHTML = prescriptionListHTML;
    }); //update prescription in db
  }



  return (
    <>
      <Card>
        <Card.Body>
          <h2 className='text-center mb-4'>Pharmacy</h2>
          {error && <div>{error}</div>}
          <hr />
          {currentRole}
          <hr />

          <div className="w-100 text-center mt-2" id="prescriptionList">
          </div>

          <hr />
          <div className="w-100 text-center mt-2">
            <Button className="btn-danger" onClick={handleLogout}>Logout</Button>
          </div>
        </Card.Body>
      </Card>
        
    </>
  )
}
