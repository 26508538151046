import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import Pharmacy from './dashboards/Pharmacy'
import Provider from './dashboards/Provider'
import AdminProvider from './dashboards/AdminProvider'
import Admin from './dashboards/Admin'


export default function PrivateRoute() {
    const { currentUser, currentRole } = useAuth()

    if (currentUser && currentRole === "pharmacy") {
        return <Pharmacy />
    } else if (currentUser && currentRole === "admin") {
        return <Admin/>
    } else if (currentUser && currentRole === "provider") {
        return <Provider />
    } else if (currentUser && currentRole === "pharmacy") {
        return <Pharmacy />
    } else if (currentUser && currentRole === "providerAdmin") {
        return <AdminProvider />
    } else {
        return <Navigate to="/login" />
    }

}
